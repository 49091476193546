import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const GACode = process.env.REACT_APP_GA || false;

if (GACode) {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    const {
      location: { pathname },
    } = props;

    useEffect(() => {
      if (GACode) trackPage(pathname);
    }, [pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
