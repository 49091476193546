import colors from './colors';

export default {
  level1: {
    color: colors.primary,
  },
  level2: {
    color: colors.primary,
  },
  level3: {
    color: colors.primary,
  },
  level4: {
    color: colors.primary,
  },
  level5: {
    color: colors.primary,
  },
  level6: {
    color: colors.primary,
  },
};
