import colors from './colors';

export default {
  base: {
    backgroundColor: colors.primaryInvert,
    color: colors.primary,
  },
  slim: {
    innerPadding: '5px 15px',
    fontSize: '12px',
  },
  medium: {
    innerPadding: '10px 30px',
    fontSize: '16px',
  },
  large: {
    innerPadding: '20px 60px',
    fontSize: '24px',
  },
  disabled: {
    backgroundColor: '#EFEFEF',
    color: '#999',
  },
  hover: {
    backgroundColor: '#ECECEC',
    color: '#333',
  },
};
