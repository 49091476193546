export default {
  translation: {
    lng: 'nl',
    fallbackLng: 'nl',
    //  debug: true,

    whitelist: ['nl'],

    localesPath: './public/locales/',

    react: {
      //  useSuspense: false
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  },
};
