import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { CookiesProvider } from 'react-cookie';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';

import withTracker from './lib/withTracker';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  
  html {
    font-family: 'Inter', Roboto, 'Helvetica Neue', Arial, sans-serif;
    text-rendering: optimizeLegibility;
    font-size: 14px;
  }
  @supports (font-variation-settings: normal) {
    html { 
      font-family: 'Inter var', Roboto, 'Helvetica Neue', Arial, sans-serif;
    }
  }
  p {
    line-height: 1.6rem;
    color: ${theme.colors.black}
  }
`;

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const Pages = {
  Events: lazy(() => import('./pages/Events')),
  Home: lazy(() => import('./pages/Home')),
  Library: lazy(() => import('./pages/Library')),
  NewMember: lazy(() => import('./pages/NewMember')),
  Privacy: lazy(() => import('./pages/Privacy')),
  Cookies: lazy(() => import('./pages/Cookies')),
};

const AppRouter = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <CookiesProvider>
          <ParallaxProvider>
            <Suspense fallback="loading">
              <Reset />
              <GlobalStyle />
              <Router>
                <Switch>
                  <Route path="/" exact component={withTracker(Pages.Home)} />
                  <Route path="/houtcollectie" component={withTracker(Pages.Library)} />
                  <Route path="/lid-worden" component={withTracker(Pages.NewMember)} />
                  <Route path="/activiteiten" component={withTracker(Pages.Events)} />
                  <Route path="/privacybeleid" component={withTracker(Pages.Privacy)} />
                  <Route path="/cookiebeleid" component={withTracker(Pages.Cookies)} />
                </Switch>
              </Router>
            </Suspense>
          </ParallaxProvider>
        </CookiesProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </ThemeProvider>
);

export default AppRouter;
