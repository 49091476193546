// tslint:disable: object-literal-sort-keys
import colors from 'components/theme/general/colors';
import button from 'components/theme/general/button';
import checkbox from 'components/theme/general/checkbox';
import radiobutton from 'components/theme/general/radiobutton';
import textfield from 'components/theme/general/textfield';
import spinner from 'components/theme/general/spinner';
import icon from 'components/theme/general/icon';
import title from 'components/theme/general/title';
// import paragraph from 'components/theme/general/paragraph';
// import formfield from 'components/theme/general/formfield';

export default {
  button,
  checkbox,
  radiobutton,
  textfield,
  spinner,
  icon,
  title,
  // paragraph,
  // formfield,
  colors: {
    ...colors,
    snow: 'rgba(255, 255, 255, 1)',
    white: 'rgba(252, 250, 250, 1)',
    fog: 'rgba(230, 241, 243, 1)',
    cloud: 'rgba(200, 211, 213, 1)',
    silver: 'rgba(164, 184, 196, 1)',
    concrete: 'rgba(51, 63, 79, 1)',
    black: 'rgba(10, 13, 18, 1)',
    gras: 'rgba(0, 140, 68, 1)',
    jungle: 'rgba(0, 166, 81, 1)',
    red: 'rgba(210, 8, 81, 1)',
    orange: '#ED6342',
    blue: '#0074d9',
  },
  zIndex: {
    menu: 110,
    header: 100,
    cookieConsent: 101,
    unsupportedBrowsersMessage: 102,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

// https://coolors.co/fcfafa-008c44-00a651-c8d3d5-a4b8c4
