export default {
  primary: '#666',
  primaryInvert: '#FFF',
  secondary: '#000',
  secondaryInvert: '#000',
  tertiary: '#000',
  tertiaryInvert: '#000',
  copy: '#000',
  copyInvert: '#000',
};
