import colors from './colors';

export default {
  base: {
    color: colors.primary,
    fontSize: '16px',
  },
  disabled: {
    backgroundColor: '#EFEFEF',
    color: '#999',
  },
};
